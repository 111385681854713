<template>
    <el-main ref="main">
        <el-tabs v-model="step" @tab-click="tabSwich">
            <el-tab-pane label="结算明细" name="1">
                <el-form class="el-form-search" label-width="100px">
                    <el-form-item label="分销员：">
                        <el-input size="small" placeholder="请输入昵称" v-model="searchForm.distribution_nickname"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号：">
                        <el-input size="small" placeholder="请输入手机号" v-model="searchForm.distribution_mobile"></el-input>
                    </el-form-item>
                    <el-form-item label="订单编号：">
                        <el-input size="small" placeholder="请输入订单编号" v-model="searchForm.pay_sn"></el-input>
                    </el-form-item>
                    <el-form-item label="佣金状态：">
                        <el-select v-model="searchForm.status" size="small">
                            <el-option label="全部" :value="0"></el-option>
                            <el-option label="待入账" :value="1"></el-option>
                            <el-option label="已入账" :value="2"></el-option>
                            <el-option label="已退款" :value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="推广关系：">
                        <el-select v-model="searchForm.type" size="small">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="直接佣金" :value="1"></el-option>
                            <el-option label="间接佣金" :value="2"></el-option>
                            <el-option label="次间接佣金" :value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label=" " label-width="25px">
                        <el-button type="primary" size="small" @click="getCommissionLog">搜索</el-button>
                        <el-button size="small" class="boderBlue" @click="exportEx">导出</el-button>
                        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
                    </el-form-item>
                </el-form>
                <el-table :data="commissionLog" :header-cell-style="{ 'background-color': '#F8F9FA' }">
                    <template slot="empty">
                        <No />
                    </template>
                    <el-table-column prop="pay_sn" label="订单编号" align="center"></el-table-column>
                    <el-table-column prop="user_nickname" label="购买人昵称" align="center"></el-table-column>
                    <el-table-column prop="user_mobile" label="购买人手机号" align="center"></el-table-column>
                    <el-table-column prop="distribution_nickname" label="分销员昵称" align="center"></el-table-column>
                    <el-table-column prop="distribution_mobile" label="手机号" align="center"></el-table-column>
                    <el-table-column prop="level_name" label="分销员等级" align="center"></el-table-column>
                    <el-table-column prop="comefrom_icon" label="来源" align="center">
                        <template v-slot="{ row }">
                            <el-image :src="row.comefrom_icon"></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column prop="amount" label="佣金金额" align="center"></el-table-column>
                    <el-table-column prop="type" label="推广关系" align="center">
                        <template v-slot="{ row }">
                            {{ row.type == 1 ? '直接佣金' : row.type == 2 ? '间接佣金' : '次间接佣金' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="结算状态" align="center">
                        <template v-slot="{ row }">
                            {{ row.status == 0 ? '待入账' : row.status == 1 ? '已入账' : '已退款' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="date" label="订单时间" align="center"></el-table-column>
                </el-table>
                <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows"
                    @updatePageNum="updateData"></Paging>
            </el-tab-pane>
            <el-tab-pane label="结算设置" name="2">
                <el-form style="width: 700px" label-width="100px">
                    <el-form-item label="提现方式：">
                        <el-radio-group v-model="type">
                            <el-radio :label="1">提现到银行卡</el-radio>
                            <el-radio :label="2">提现到微信钱包</el-radio>
                        </el-radio-group>
                        <p>提现到微信钱包，仅支持微信小程序。其他应用，会默认银行卡。</p>
                    </el-form-item>
                    <el-form-item label="提现额度：">
                        <span>提现到微信钱包，需要微信商户号开通企业打款到零钱功能</span>
                        <el-input v-model="withdraw_amount"></el-input>
                        <span>当到达此额度时才可提现，不填则表示随时可以提现</span>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
        </el-tabs>
        <Preservation v-if="step == '2'" @preservation="saveSet"></Preservation>
    </el-main>
</template>

<script>
import config from '@/util/config';
import Preservation from '@/components/preservation';
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
import wx from '@/assets/image/wx.png';
import ali from '@/assets/image/ali.png';
import douyin from '@/assets/image/douyin.png';
import baidu from '@/assets/image/baidu.png';
import qq from '@/assets/image/qq.png';
export default {
    components: {
        Preservation,
        Paging,
    },
    data () {
        return {
            step: '1',
            type: 1,
            withdraw_amount: '',
            commissionLog: [],
            searchForm: {
                page: 1,
                rows: 10,
                status: 0,
                type: '',
                distribution_mobile: '',
                distribution_nickname: '',
                pay_sn: '',
            },
            total_number: 0,
        };
    },
    created () {
        this.searchForm.distribution_nickname = this.$route.query.nickname || '';
        this.searchForm.type = Number(this.$route.query.type) || '';
        this.getWithdrawSet();
        this.getCommissionLog();
    },
    methods: {
        tabSwich () {
            if (this.step == 1) {
                this.$refs.main.$el.style.height = 'auto';
            } else {
                this.$refs.main.$el.style.height = '100%';
            }
        },
        cancelSearch () {
            this.searchForm = {
                page: 1,
                rows: 10,
                status: 0,
                distribution_mobile: '',
                distribution_nickname: '',
                pay_sn: '',
            };
            this.getCommissionLog();
        },
        updateData (val, status) {
            if (status == 0) {
                this.searchForm.rows = val;
                this.getCommissionLog();
            } else {
                this.searchForm.page = val;
                this.getCommissionLog();
            }
        },
        exportEx () {
            this.$axios.post(this.$api.distribution.exportCommissionLog).then(res => {
                if (res.code == 0) {
                    let path = config.baseurl + '/' + res.result;
                    let a = document.createElement('a');
                    a.href = path;
                    a.id = 'download';
                    document.body.appendChild(a);
                    a.click();
                    let aDom = document.getElementById('download');
                    document.body.removeChild(aDom);
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getCommissionLog () {
            this.$axios.post(this.$api.distribution.commissionLog, this.searchForm).then(res => {
                if (res.code == 0) {
                    let list = res.result.list;
                    list.map(item => {
                        item.date = getDateformat(item.create_time);
                        if (item.comefrom == 1) {
                            item.comefrom_icon = wx;
                        }
                        if (item.comefrom == 2) {
                            item.comefrom_icon = ali;
                        }
                        if (item.comefrom == 4) {
                            item.comefrom_icon = douyin;
                        }
                        if (item.comefrom == 5) {
                            item.comefrom_icon = qq;
                        }
                        if (item.comefrom == 6) {
                            item.comefrom_icon = baidu;
                        }
                    });
                    this.commissionLog = list;
                    this.total_number = res.result.total_number;
                }
            });
        },
        getWithdrawSet () {
            this.$axios.post(this.$api.distribution.withdrawInfo).then(res => {
                if (res.code == 0) {
                    if (res.result) {
                        this.type = res.result.type;
                        this.withdraw_amount = res.result.withdraw_amount;
                    }
                }
            });
        },
        saveSet () {
            this.$axios
                .post(this.$api.distribution.withdrawEdit, {
                    type: this.type,
                    withdraw_amount: this.withdraw_amount,
                })
                .then(res => {
                    if (res.code == 0) {
                        this.$message.success('保存成功');
                    } else if (res.code == -2) {
                        this.$confirm(res.msg, '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning',
                        }).then(() => {
                            this.$router.push('/set/Cashier');
                        });
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;
}
</style>
